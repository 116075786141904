import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Header />
        <header></header>

        <div id="sections">
          <section>
            <h2>Elderly Rehabilitation & Home Visits</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-160-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Reduced Mobility</li>
                  <li>Falls Prevention</li>
                  <li>Care Homes</li>
                  <li>Dementia</li>
                  <li>Reverse Osteoporosis</li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/06-2024-MidwinterPhysiotherapy-7065.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </section>

          <section>
            <h2>Neurological Rehabilitation</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-440.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Learning Disabilities</li>
                  <li>Parkinsons Disease</li>
                  <li>Traumatic Brain Injuries</li>
                  <li>Spinal Cord Injury</li>
                  <li>Dementia</li>
                  <li>Stroke</li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-142-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </section>

          <section>
            <h2>Respiratory Physiotherapy</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-306.webp')",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Breathing Pattern Disorders</li>
                  <li>Chest Clearance</li>
                  <li>Breathlessness</li>
                  <li>Recurrent Infections</li>
                  <li>Pulmonary Rehabilitation</li>
                  <li>Long Covid</li>
                  <li>POTS (Postural Orthostatic Tachycardia Syndrome)</li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-352-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </section>

          <section>
            <h2>Musculoskeletal Physiotherapy</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-239-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Injuries</li>
                  <li>Chronic Pain</li>
                  <li>Pre/Post Operative</li>
                  <li>Arthritis</li>
                  <li>Mobility Problems</li>
                  <li>
                    <a
                      href="https://midwinter-physiotherapy.uk1.cliniko.com/bookings?business_id=1546552212656755968"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i>Book online*</i>
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-088-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <p style={{ marginTop: "10px", fontSize: "1rem" }}>
              * You can self refer into our musculoskeletal clinic by{" "}
              <a
                href="https://midwinter-physiotherapy.uk1.cliniko.com/bookings?business_id=1546552212656755968"
                target="_blank"
                rel="noreferrer"
              >
                booking online
              </a>
            </p>
          </section>

          <section>
            <h2>Aquatic & Hydrotherapy</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-388-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Reduced Mobility</li>
                  <li>Learning Disabilities</li>
                  <li>Post Operative</li>
                  <li>Pain</li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-399.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </section>

          <section>
            <h2 style={{ color: "#ef199f" }}>Care Home Training Packages</h2>

            <a
              href="https://wecarefully.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              <div id="carefully-card"></div>
            </a>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Home;
